import '@babel/polyfill'
import cssVars from 'css-vars-ponyfill'
cssVars({})
import Vue from 'vue'
import axios from 'axios'
import Cookies from 'js-cookie'

// import 'normalize.css/normalize.css' // a modern alternative to CSS resets

import Element from 'element-ui'
import './styles/element-variables.scss'
import enLang from 'element-ui/lib/locale/lang/en'// 如果使用中文语言包请默认支持，无需额外引入，请删除该依赖

import '@/styles/index.scss' // global css

import App from './App'
import store from './store'
import router from './router'

import './icons' // icon
// import './permission' // permission control
// import './utils/error-log' // error log

import * as filters from './filters' // global filters

import cookie from 'vue-cookie'
Vue.prototype.$cookie = cookie;
function setPageSizeIndex(size){
  Vue.prototype.pageSizeIndex = size
  cookie.set('pageSizeIndex', size, 99999);
}
Vue.prototype.pageSizeIndex = cookie.get('pageSizeIndex');
if(Vue.prototype.pageSizeIndex){
  Vue.prototype.pageSizeIndex = parseInt(Vue.prototype.pageSizeIndex)
}else{
  setPageSizeIndex(10)
}

Vue.prototype.setPageSizeIndex = setPageSizeIndex

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
// if (process.env.NODE_ENV === 'production') {
//   const { mockXHR } = require('../mock')
//   mockXHR()
// }
import VueAMap from 'vue-amap'
Vue.use(VueAMap)
VueAMap.initAMapApiLoader({
  key: '3ebdb3c7a684a4e64f39ddd306056522',
  plugin: ['AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PlaceSearch', 'AMap.Geolocation', 'AMap.Geocoder'],
  v: '1.4.4',
  uiVersion: '1.0'})

// key： 高德的key（自己申请）
//
// plugin： 引用的插件（根据需要引入）
//
// v： 高德SDK 版本
//
// uiVersion：UI库 版本

var instance = axios.create({
  baseURL:'',
  timeout:5000,
  headers:{"Content-Type":"multipart/form-data"}
});

Vue.prototype.axios = axios;
Vue.prototype.instance=instance;

Vue.use(Element, {
  size: Cookies.get('size') || 'medium', // set element-ui default size
  // locale: enLang // 如果使用中文，无需设置，请删除
})

// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.config.productionTip = false

window.Vue = new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
