import { render, staticRenderFns } from "./Rightmenu.vue?vue&type=template&id=3c847782&scoped=true&"
import script from "./Rightmenu.vue?vue&type=script&lang=js&"
export * from "./Rightmenu.vue?vue&type=script&lang=js&"
import style0 from "./Rightmenu.vue?vue&type=style&index=0&id=3c847782&scoped=true&lang=css&"
import style1 from "./Rightmenu.vue?vue&type=style&index=1&id=3c847782&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c847782",
  null
  
)

export default component.exports